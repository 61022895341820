.chart {
    position: relative;
    border-radius: 8px;
    background: #fff;
    border: 1px solid var(--border-color);
}

.chart__header {
    position: relative;
    display: flex;
    align-items: center;
    height: 56px;
    padding: 4px 16px;
    border-bottom: 1px solid var(--border-color);
}

.chart__headerTitle {
    margin-right: 16px;
    font-size: 16px;
    line-height: 1.125;
}

.chart__headerEnd {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.chart__body {
    position: relative;
    height: 320px;
    padding: 16px 0 16px;
}

.chart__body .ant-spin-nested-loading, 
.chart__body .ant-spin-container {
    height: 100%;
}
