.periodChooser .ant-picker {
    background-color: transparent;
    border-width: 0;
    box-shadow: none;
    padding: 0;
    width: 160px;
}

.periodChooser .ant-segmented-item-selected .ant-picker {
    background-color: #fff;
}