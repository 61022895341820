.logo {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.logo:before,
.logo:after {
    content: '';
    position: absolute;
    top: -8px;
    bottom: -8px;
    left: -12px;
    right: -12px;
    border-radius: 42px;
}

.logoImg {
    position: relative;
    z-index: 1;
    width: 100%;
    object-fit: contain;
}

.appSider .logo {
    position: absolute;
    top: 16px;
    left: 12px;
    width: 150px;
}
.appSider.ant-layout-sider-collapsed .logo {
    left: 16px;
    width: 48px;
    height: 48px;
}

.appSider.ant-layout-sider-collapsed .logo:before,
.appSider.ant-layout-sider-collapsed .logo:after {
    top: -8px;
    bottom: -8px;
    left: -8px;
    right: -8px;
}

.appSider.ant-layout-sider-collapsed .logoImg {
    width: 48px;
}

.logo__text {
    text-align: center;
}

.logo__title {
    color: #EE4F37;
    font-size: 28px;
    font-weight: 600;
}

.logo__subtitle {
    position: relative;
    color: #fff;
    font-size: 12px;
    font-weight: 400;
    padding-top: 8px;
}

.logo__subtitle:before {
    content: '';
    position: absolute;
    top: 2px;
    left: 50%;
    transform: translateX(-50%);
    width: 40px;
    height: 1px;
    background-color: rgba(255, 255, 255, .5);
}
