.pageHeader {
    position: relative;
    z-index: 2;
    padding: 0 24px;
    width: 100%;
    height: 60px;
    background: #fff;
    border-bottom: 1px solid var(--border-color);
}

.page__witTabs .pageHeader {
    height: 80px;
}

.pageHeaderMain {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    padding: 0;
    height: 60px;
}

.pageHeader__start {
    position: relative;
    display: flex;
    align-items: center;
}

.pageHeader__middle {
    flex: 1;
    display: flex;
    align-items: center;
    gap: 16px;
}

.pageHeader__end {
    display: flex;
    align-items: center;
}

.pageHeaderTitle {
    margin-right: 16px;
    font-size: 18px;
    font-weight: 500;
    line-height: 40px;
}

.pageHeaderInfo {
    margin-top: 8px;
    font-size: 14px;
    color: var(--light-color);
}

.pageHeader .backBtn {
    margin-right: 12px;
}