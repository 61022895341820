.status {
    display: inline-flex;
    align-items: center;
    white-space: nowrap;
    padding: 4px 8px 4px 8px;
    font-size: 12px;
    line-height: 1.125;
    border-radius: 2em;
    color: rgba(0, 0, 0, .88);
    background: rgba(0, 0, 0, .02);
    border: 1px solid #d9d9d9;
}

.status .anticon {
    margin-right: 4px;
    font-size: 16px;
}

.status.new {
    color: #1d9dd0;
    background: #E6F5FB;
    border-color: #90dfff;
}

.status.checking, .status.waiting {
    color: #d46b08;
    background: #fff7e6;
    border-color: #ffd591;
}

.status.checked, .status.completed {
    color: #0c9a0c;
    background: #dafeda;
    border-color: #8fec8f;
}

.status.changes_rejected {
    color: var(--ant-error-color);
    background: #fdebeb;
    border-color: var(--ant-error-color-outline);
}

.status.banned, .status.canceled {
    color: rgb(104, 104, 104);
    background: #dbdbdb;

    color: #626262;
    background: #e0e0e0;
    border-color: #b8b8b8;
}