.smartTable {
    position: relative;
    border-radius: 8px;
    background: #fff;
    border: 1px solid var(--border-color);
}


.smartTable__header {
    position: relative;
    display: flex;
    align-items: center;
    height: 56px;
    padding: 4px 16px;
    border-bottom: 1px solid var(--border-color)
}


.smartTable__headerTitle {
    margin-right: 16px;
    font-size: 16px;
    line-height: 1.125;
}

.smartTable__headerEnd {
    flex: 1;
}

.smartTable .smartFilterValuesWrap {
    position: relative;
    transform: translateY(0);
    margin: 0;
}

.smartTable .smartFilterValues {
    padding: 8px 16px;
}

.smartTable_noHeader .smartFilterValuesWrap {
    border-radius: 8px 8px 0 0;
}