.billingSider.ant-layout-sider {
    height: 100vh;
    background: var(--sider-bg-color2);
    border-right: 1px solid var(--border-color);
    padding: 24px 0 0;
}

.billingSider .ant-layout-sider-children {
    overflow-y: scroll;
    overflow-x: hidden;

    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */

    &::-webkit-scrollbar {
        display: none;
    }
}
